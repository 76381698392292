
import {Dimensions, Image, Text, TextInput, TouchableOpacity, View} from "react-native-web";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {normalize} from "../App";
import multiPic from "../uploadedAssets/multipic.png";

const {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
} = Dimensions.get('window');

function AboutScreen() {
    let navigate = useNavigate();

    return (
        <View>
            {SCREEN_WIDTH < 600 ?
                <View style={{marginTop: 30, width: "100%", alignItems: 'center',}}>
                    <Text style={{fontWeight: '800', fontSize: normalize(30)}}>About the Book</Text>
                    <Text style={{fontWeight: '800', fontStyle: "italic", fontSize: normalize(12), marginBottom: 30}}>Not just for the little ones ❤️</Text>

                    <Image source={multiPic} style={{maxWidth: 380, maxHeight: 450, width: SCREEN_WIDTH * .5, height: (SCREEN_WIDTH * .55), resizeMode: "contain"}}/>
                    <Text style={{fontWeight: "bold", fontSize: normalize(18), marginTop: 30, marginLeft: 30, marginRight: 40, marginBottom: 100}}>{"A child is life's greatest blessing. " +
                        "Our family was blessed with four beautiful boys, two of them at the same time! " +
                        "That being said, messes are a part of our every day life, and I quickly realized that I could either be upset by the mess," +
                        " or make it a teachable moment. Not just teachable for my boys, but for me as well." +
                        "\n\nChildren love to imitate, children love to help, and they soak up their surroundings. Little ones learn from example. " +
                        "They make messes on accident. Most times, messes are made in the attempt to imitate their family, because we are their biggest supporters," +
                        " their biggest fans, and their best teachers. What an honor and a blessing to have our own children want to imitate us!" +
                        "\n\nEncourage your children to be helpful and kind. Kindness is so important, it's like a superhero power. " +
                        "Teach your little ones to be kind and compassionate in all they do. Remind them to ask permission, take care of their animals, always help their family and friends, and to do their very best while doing it." +
                        " If a mess is made in the process then that's a bonus. Smile and snap a picture. Time goes by too fast. " +
                        "Hug that little superhero of yours and remember, memories are in the mess… the bigger the mess, the better the memory.\n\n"}
                    </Text>
                </View>
                :
                <View style={{marginTop: 30, width: "100%", alignItems: 'center',}}>
                    <Text style={{fontWeight: '800', fontSize: normalize(30)}}>About the Book</Text>
                    <Text style={{fontWeight: '800', fontStyle: "italic", fontSize: normalize(12), marginBottom: 30}}>Not just for the little ones ❤️</Text>
                    <View style={{flexDirection: "row"}}>
                        <Image source={multiPic} style={{maxWidth: 380, maxHeight: 450, width: SCREEN_WIDTH * .5, height: (SCREEN_WIDTH * .55), resizeMode: "contain"}}/>
                        <Text style={{fontWeight: "bold", fontSize: normalize(18), marginLeft: 30, marginRight: 40, marginBottom: 100}}>{"A child is life's greatest blessing. " +
                            "Our family was blessed with four beautiful boys, two of them at the same time! " +
                            "That being said, messes are a part of our every day life, and I quickly realized that I could either be upset by the mess," +
                            " or make it a teachable moment. Not just teachable for my boys, but for me as well." +
                            "\n\nChildren love to imitate, children love to help, and they soak up their surroundings. Little ones learn from example. " +
                            "They make messes on accident. Most times, messes are made in the attempt to imitate their family, because we are their biggest supporters," +
                            " their biggest fans, and their best teachers. What an honor and a blessing to have our own children want to imitate us!" +
                            "\n\nEncourage your children to be helpful and kind. Kindness is so important, it's like a superhero power. " +
                            "Teach your little ones to be kind and compassionate in all they do. Remind them to ask permission, take care of their animals, always help their family and friends, and to do their very best while doing it." +
                            " If a mess is made in the process then that's a bonus. Smile and snap a picture. Time goes by too fast. " +
                            "Hug that little superhero of yours and remember, memories are in the mess… the bigger the mess, the better the memory.\n\n"}
                        </Text>
                    </View>
                </View>
            }
        </View>
    );

};

export default AboutScreen;
