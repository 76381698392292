
import React, {useState} from "react";
import {Dimensions, Image, Text, TextInput, TouchableOpacity, View} from "react-native-web";
import {useNavigate} from "react-router-dom";
import {normalize} from "../App";
import ReactDOM from "react-dom";
import coverImage from "../uploadedAssets/bookcover.png";
import multiPic from "../uploadedAssets/multipic.png"

const {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
} = Dimensions.get('window');

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });


function HomeScreen() {

    const [emailAddress, setEmailAddress] = useState("")
    const [havePurchase, setHavePurchase] = useState(false)
    let navigate = useNavigate();

    function _createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "15.98",
                    },
                },
            ],
        });
    }

    async function _onApprove(data, actions) {
        let order = await actions.order.capture();
        console.log(order);
        setHavePurchase(true)
        setEmailAddress(order.payer.email_address)
        return order;
    }

    return (
        <View>
            {SCREEN_WIDTH < 600 ?
                <View style={{marginLeft: 30, marginTop: 20, marginRight: 30}}>
                    <Image style={{width: SCREEN_WIDTH - 60, height: (SCREEN_WIDTH - 60) * .85, backgroundColor: "#d0d0d0", marginBottom: 15}}
                            source={coverImage}/>
                    <Text style={{fontWeight: "bold", fontSize: normalize(24)}}>Our Busy Day</Text>
                    <Text style={{fontStyle: "italic", fontSize: normalize(13), marginLeft: 2, marginTop: -3}}>by Kelly Billig • Paperback Edition</Text>
                    <View style={{flexDirection: "row", alignItems: "flex-end"}}>
                        <Text style={{fontWeight: "bold", fontSize: normalize(22), marginTop: 10, marginBottom: -4}}>$11.99</Text>
                        <Text style={{fontStyle: "italic", fontSize: normalize(12), marginLeft: 7,}}>+ $3.99 shipping & handling</Text>
                    </View>

                    <View style={{shadowOffset: { width: 2, height: 5}, shadowOpacity: 0.5, shadowRadius: 10, elevation: 15, marginTop: 20, marginLeft: -5, marginRight: -5, paddingLeft: 10, paddingRight: 10, borderRadius: 10, borderWidth: 1, borderColor: "black", borderStyle: "solid", marginBottom: 50}}>
                        <View>
                            {!havePurchase ?
                            <View>
                                <Text style={{marginLeft: 3, color: "#282828", marginTop: 12, marginBottom: 12, fontWeight: "bold", fontStyle: 'italic', fontSize: normalize(13)}}>Choose a Checkout Method</Text>
                                <PayPalButton
                                    createOrder={(data, actions) => _createOrder(data, actions)}
                                    onApprove={(data, actions) => _onApprove(data, actions)}
                                />
                            </View> :
                            <View style={{justifyItems: 'center', justifyContent: "center"}}>
                                <Text style={{marginLeft: 3, color: "#235735", marginTop: 12, marginBottom: 12, fontWeight: "800", fontStyle: 'italic', textAlign: "center", fontSize: normalize(15)}}>Thank you for your order!</Text>
                                <Text style={{marginLeft: 3, color: "#235735", marginBottom: 12, fontWeight: "800", fontStyle: 'italic', fontSize: normalize(13), textAlign: 'center'}}>{"You'll receive an email at\n" + emailAddress + "\nwhen your order ships!"}</Text>
                            </View>
                            }
                        </View>
                    </View>

                    <View style={{
                        marginTop: -10, marginBottom: 40,
                        borderBottomColor: '#dedede',
                        borderBottomWidth: 1}}/>

                    <View style={{flexDirection: "row", alignItems: 'center'}}>
                        <Text style={{width: "70%", fontWeight: "800", fontSize: normalize(18)}}>{"\"The memories are in the mess…\n\nThe bigger the mess, the better the memory.\"\ "}</Text>
                        <Image source={multiPic} style={{maxWidth: 380, maxHeight: 450, width: SCREEN_WIDTH * .7, height: (SCREEN_WIDTH) * .75, resizeMode: "contain"}}/>
                    </View>

                    <TouchableOpacity
                        onPress={() => navigate("/about")}
                        style={{marginBottom: 150, marginTop: 40, width: "50%", alignSelf: 'center', backgroundColor: 'rgba(82,173,248, 1)', borderRadius: 15, padding: 10}}>
                        <Text style={{fontWeight: "bold", color: 'white', textAlign: 'center', fontSize: normalize(14)}}>Learn More</Text>
                    </TouchableOpacity>

                </View> :
                <View>
                    <View style={{marginLeft: 30, marginTop: 20, flexDirection: "row", alignItems: 'center'}}>
                        <Image style={{maxWidth: 450, maxHeight: 380, width: SCREEN_WIDTH * .5, height: (SCREEN_WIDTH * .5) * .85, backgroundColor: "#d0d0d0"}}
                               source={coverImage}/>
                        <View style={{marginLeft: 20}}>
                            <Text style={{fontWeight: "bold", fontSize: normalize(24)}}>Our Busy Day</Text>
                            <Text style={{fontStyle: "italic", fontSize: normalize(10), marginLeft: 2, marginTop: -3}}>by Kelly Billig • Paperback Edition</Text>
                            <View style={{flexDirection: "row", alignItems: "flex-end"}}>
                                <Text style={{fontWeight: "bold", fontSize: normalize(22), marginTop: 10, marginBottom: -4}}>$11.99</Text>
                                <Text style={{fontStyle: "italic", fontSize: normalize(12), marginLeft: 7,}}>+ $3.99 shipping & handling</Text>
                            </View>

                            <View style={{shadowOffset: { width: 2, height: 5}, shadowOpacity: 0.5, shadowRadius: 10, elevation: 15, marginTop: 20, marginLeft: -5, marginRight: -5, paddingLeft: 10, paddingRight: 10, borderRadius: 10, borderWidth: 1, borderColor: "black", borderStyle: "solid", marginBottom: 50}}>
                                <View>
                                    {!havePurchase ?
                                        <View>
                                            <Text style={{marginLeft: 3, color: "#282828", marginTop: 12, marginBottom: 12, fontWeight: "bold", fontStyle: 'italic', fontSize: normalize(13)}}>Choose a Checkout Method</Text>
                                            <PayPalButton
                                                createOrder={(data, actions) => _createOrder(data, actions)}
                                                onApprove={(data, actions) => _onApprove(data, actions)}
                                            />
                                        </View> :
                                        <View style={{justifyItems: 'center', justifyContent: "center"}}>
                                            <Text style={{marginLeft: 3, color: "#235735", marginTop: 12, marginBottom: 12, fontWeight: "800", fontStyle: 'italic', textAlign: "center", fontSize: normalize(15)}}>Thank you for your order!</Text>
                                            <Text style={{marginLeft: 3, color: "#235735", marginTop: 12, marginBottom: 12, fontWeight: "800", fontStyle: 'italic', textAlign: 'center', fontSize: normalize(13)}}>{"You'll receive an email at\n" + emailAddress + "\nwhen your order ships!"}</Text>
                                        </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            }
        </View>
    );
};

export default HomeScreen;
