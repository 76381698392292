
import React from "react";
import {NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Text, TouchableOpacity, View} from "react-native-web";
import { Dimensions, PixelRatio } from 'react-native-web';
import ReactDOM from "react-dom";

import AboutScreen from "./screens/about";
import HomeScreen from "./screens/home";

const {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
} = Dimensions.get('window');

let scale = SCREEN_WIDTH / 350;

export function normalize(size) {
    if (scale >= 1.3){
        scale = 1.3;
    }
    const newSize = size * scale
    return Math.round(PixelRatio.roundToNearestPixel(newSize))
}

function App() {
  const navigate = useNavigate()
  const location = useLocation();

  return (
      <View>

        <View style={{flexDirection: 'row', borderRadius: 20, backgroundColor: '#f1f1f1', height: 60, paddingRight: 10, alignSelf: 'center', marginTop: 20, alignItems: 'center', width: "95%"}}>
            <View style={{marginLeft: 20, marginRight: 20}}>
                <Text style={{fontWeight: '800', fontSize: normalize(14)}}>Our Busy Day</Text>
                <Text style={{marginLeft: 2, fontSize: normalize(10)}}>by Kelly Billig</Text>
            </View>
            <View style={{width:5}}/>
            <TouchableOpacity style={location.pathname === '/' ? {backgroundColor: 'rgba(82,173,248,0.8)', paddingTop: 15, paddingBottom: 15, borderRadius: 20} : {}} onPress={() => navigate('/')}>
                <Text style={location.pathname === '/' ? {color: 'white', fontWeight: 'bold', fontSize: normalize(14), paddingLeft: 15, paddingRight: 15, textAlign: 'center', textShadowColor: '#000',
                    textShadowOffset: {width: 0, height: 1}, textShadowRadius: 3} : {color: '#52adf8', fontWeight: 'bold', fontSize: normalize(14), paddingLeft: 15, paddingRight: 15, textAlign: 'center'}}>Purchase</Text>
            </TouchableOpacity>
            <View style={{width:5}}/>
            <TouchableOpacity style={location.pathname.includes('/about') ? {backgroundColor: 'rgba(82,173,248,0.8)', paddingTop: 15, paddingBottom: 15, borderRadius: 20} : {}} onPress={() => navigate('/about')}>
                <Text style={location.pathname.includes('/about') ? {color: 'white', fontWeight: 'bold', fontSize: normalize(14), paddingLeft: 15, paddingRight: 15, textAlign: 'center', textShadowColor: '#000',
                    textShadowOffset: {width: 0, height: 1}, textShadowRadius: 3} : {color: '#52adf8', fontWeight: 'bold', fontSize: normalize(14), paddingLeft: 15, paddingRight: 15, textAlign: 'center'}}>About</Text>
            </TouchableOpacity>
        </View>

        <Routes>
          <Route exact path={'/'} element={<HomeScreen/>}/>
          <Route path={'/about'} element={<AboutScreen/>}/>
        </Routes>
      </View>
  );
}


export default App;
